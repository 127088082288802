<template>
  <div class="container">
    <a-scene physics id="theScene" camera-transform-controls embedded>
      <a-entity
        id="cameraRig"
        position="10 1.6 40"
        rotation="0 180 0"
        wasd-controls="fly: true"
        movement-controls="fly: true"
        look-controls
      >
        <a-entity
          id="lefthand"
          camera-transform-controls-hand="hand: left"
          laser-controls="hand: left"
          thumbstick-goto=""
        ></a-entity>
        <a-entity
          id="righthand"
          camera-transform-controls-hand="hand: right"
          laser-controls="hand: right"
        ></a-entity>
        <a-entity id="camera" camera>
          <a-entity
            position="2 0.3 -1.4"
            rotation="0 -35 0"
            id="nodeDesc"
            geometry="primitive: plane"
            material="color: #131383; transparent: true; opacity: 0.05;"
            text="value: 1-wide\ndefault."
            visible="false"
          ></a-entity>
        </a-entity>
      </a-entity>

      <a-entity
        ref="forceGraph"
        id="forceGraph"
        scale="0.2 0.2 0.2"
        :forcegraph="neo4jDump"
      >
      </a-entity>
    </a-scene>
  </div>
</template>

<script>
import 'aframe';
import 'aframe-extras';
import 'aframe-forcegraph-component';
import '@/plugins/aframe-camera-transform-controls-component';

import { mapActions } from 'vuex';

export default {
  name: 'Thoughtverse',

  mounted() {},

  methods: {
    ...mapActions({
      graphDumpFetch: 'graphDumpFetch',
    }),
  },

  created() {
    // this.graphDumpFetch();
  },
  computed: {
    neo4jDump() {
      const graphFromState = this.$store.state.graph;
      const graphSettings = this.$store.state.graph.setting;

      const filteredNodeList = graphFromState.nodes.filter(() => true);
      // if (graphSettings.nodeFilter[labelToCheck] && graphSettings.nodeFilter[labelToCheck].isSelected > 0) {
      //   return true;
      // }
      // return false;
      const filteredEdgeList = graphFromState.links.filter((edge) => {
        const labelToCheck = edge.label;

        if (
          graphSettings.edgeFilter[labelToCheck]
          && graphSettings.edgeFilter[labelToCheck].isSelected > 0
        ) {
          return true;
        }
        return false;
      });

      const nodeList = JSON.stringify(filteredNodeList).replaceAll(';', '.'); // sanatization to fit aframe component arg structure
      const edgeList = JSON.stringify(filteredEdgeList).replaceAll(';', '.');

      const forceGraphAttr = {
        nodes: nodeList,
        links: edgeList,
        'node-auto-color-by': 'nodeLabel',
        'node-label': 'title',
        'link-auto-color-by': 'label',
        'link-label': 'label',
        'link-curvature': 0.3,

        // 'link-directional-arrow-length': 1,
        // 'link-directional-arrow-rel-pos': (e) => { return 1},
        'node-opacity': 1,
        'link-width': '1px',
      };

      const forceGraphVal = Object.keys(forceGraphAttr)
        .map((attr) => `${attr}: ${forceGraphAttr[attr]}`)
        .join(';');

      return forceGraphVal;
    },
  },

  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.container {
  height: 80vh;
}
#theScene {
  width: 100%;
  height: 100%;
}
</style>
